<template>
	<div>Loading...</div>
</template>

<script>
	import axios from "axios"
	import jwt from "jsonwebtoken"

	export default {
		components: {},
		data() {
			return {}
		},
		beforeCreate() {
			axios
				.get(this.$route.query.params + "&redirect=" + this.$route.query.redirect)
				.then((response) => {
					let userInfo = response.data.results

					const token = userInfo.JWTToken
					const decodedJWT = jwt.decode(token)
					const tokenExpiry = new Date(decodedJWT.exp * 1000)

					localStorage.setItem("accessToken", token)
					localStorage.setItem("userInfo", JSON.stringify(userInfo))
					localStorage.setItem("tokenExpiryKey", tokenExpiry)
					localStorage.setItem("loggedIn", true)
					localStorage.setItem("language", JSON.stringify({ id: 1, label: "en" }))
					this.$store.commit("AUTHDATA/setUser", userInfo)
					localStorage.setItem("ext", "yes")

					this.$router.push(userInfo.redirect_to)
				})
				.catch((err) => {
					this.$swal(`${this.$t("Failed", "Failed")}!`, err, "error")
				})
		},
		methods: {},
	}
</script>

<style>
	.vgt-global-search__input {
		display: none;
	}
</style>
